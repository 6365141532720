/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ParcelList from '../../account/ParcelList';
import { SendParcelWidget, TrackingSearchWidget } from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import * as analytics from '../../utils/analytics';
import { isBrowser } from '../../utils';
import MarketingPermissions from '../../account/MarketingPermissions';

export default ({ location, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const title = translate('account.parcels.received');
  const query = isBrowser && new URLSearchParams(location.search).get('sn');
  const { user, isLoading } = useSelector(state => state.session);

  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Box>
          <SendParcelWidget sidebar sx={{ mb: 3 }} />
          <TrackingSearchWidget sidebar />
        </Box>
      }
    >
      {!user?.marketingOptedIn && !isLoading ? (
        <MarketingPermissions />
      ) : (
        <ParcelList state={location.state} mode="received" query={query} />
      )}
    </AccountLayout>
  );
};
